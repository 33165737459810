import './Toggle.css'

export interface OnToggleClickE {
    (): void;
}

interface RenderToggleProps {
    isOn: boolean;
    onToggleClick: OnToggleClickE;
}

export const RenderToggle = (props: RenderToggleProps): JSX.Element => {
    const sliderCSS = props.isOn ? "slider sliderOn" : "slider";
    const ballCSS = props.isOn ? "ball ballOn" : "ball ballOff";
    return (
            <div className={sliderCSS} onClick={() => {props.onToggleClick()}}>
                <div className={ballCSS}>
                </div>
            </div>
    );
};
