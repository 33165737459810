import { Link } from 'react-router-dom';
import './SMHI.css'
import * as Time from './Time';
import * as FlagDays from './FlagDays';
import * as Sun from './Sun';
import { images } from '../images/images';

/**
 * Documentation of the parameters are found here:
 * http://opendata.smhi.se/apidocs/metfcst/parameters.html
 * How to get a forcast are found here:
 * http://opendata.smhi.se/apidocs/metfcst/get-forecast.html
 * Weather symbols in swedish:
 * https://www.smhi.se/kunskapsbanken/meteorologi/vad-betyder-smhis-vadersymboler-1.12109
*/

export interface SmhiForecast {
    approvedTime: string,
    referenceTime: string,
    geometry: GeometryPoint,
    timeSeries: TimeSerie[],
}

export interface GeometryPoint {
    type: string,
    coordinates: number[][],
}

export interface TimeSerie {
    validTime: string,
    parameters: Parameter[],
}

export interface Parameter {
    name: string,
    levelType: string,
    level: number,
    unit: string,
    values: Value[],
}

export type Value = number;

/**
 * Returns the value of a parameters in SMHI timeSeries.parameters.
 */
const paramFind = (params: Parameter[], name: string): Value | undefined => {
    const res = params.find( (x:Parameter):boolean => {
        return x.name === name;
    });
    return res?.values?.[0];
}

/**
 * From a list of TimeSeries, group them by there valid day.
 */
export const groupByDay = (fs: TimeSerie[]): { [k: string]: TimeSerie[]} => {
    const res: {[k: string]: TimeSerie[]} = {}; // FullDateStr: TimeSerie[]
    fs.forEach( (e) => {
        const t: string = Time.fullDateFormat(new Date(e.validTime));
        if (res[t] === undefined) {
            res[t] = [];
        }
        res[t].push(e);
    });
    return res;
}

/**
 * Reduce the number of datapoints if greater than 6 to 3 hours interval.
 */
export const reduceDataPoints = (f: TimeSerie[]): TimeSerie[] => {
    if(f.length <= 6) {
        return f;
    } else {
        const ret: TimeSerie[] = [];
        f.forEach( (e) => {
            const t = new Date(e.validTime);
            if([0,3,6,9,12,15,18,21].includes(t.getUTCHours())) {
                ret.push(e);
            }
        });
        return ret;
    }
};

export const weatherSymbolString = (wsymb2: number): string => {
    let res = "Okänd symbol";
    switch (wsymb2) {
        case 1: res = "Klart"; break;
        case 2: res = "Lätt molnighet"; break;
        case 3: res = "Halvklart"; break;
        case 4: res = "Molnigt"; break;
        case 5: res = "Mycket moln"; break;
        case 6: res = "Mulet"; break;
        case 7: res = "Dimma"; break;
        case 8: res = "Lätt regnskur"; break;
        case 9: res = "Regnskur"; break;
        case 10: res = "Kraftig regnskur"; break;
        case 11: res = "Åskskur"; break;
        case 12: res = "Lätt by av regn och snö"; break;
        case 13: res = "By av regn och snö"; break;
        case 14: res = "Kraftig by av regn och snö"; break;
        case 15: res = "Lätt snöby"; break;
        case 16: res = "Snöby"; break;
        case 17: res = "Kraftig snöby"; break;
        case 18: res = "Lätt regn"; break;
        case 19: res = "Regn"; break;
        case 20: res = "Kraftigt regn"; break;
        case 21: res = "Åska"; break;
        case 22: res = "Lätt snöblandat regn"; break;
        case 23: res = "Snöblandat regn"; break;
        case 24: res = "Kraftigt snöblandat regn"; break;
        case 25: res = "Lätt snöfall"; break;
        case 26: res = "Snöfall"; break;
        case 27: res = "Ymnigt snöfall"; break;
        default: res = "Okänd vädersymbol: " + wsymb2; break;
    }
    return res;
};

interface WindProps {
    f: TimeSerie;
}

/**
 * Render the wind information.
 */
export const Wind = (props: WindProps): JSX.Element => {
    const windDirection = paramFind(props.f.parameters, "wd");
    const windSpeed = paramFind(props.f.parameters, "ws");
    const windGustSpeed = paramFind(props.f.parameters, "gust");
    if (windDirection !== undefined && windSpeed !== undefined && windGustSpeed !== undefined) {
        const windArrowRotation = "rotate(" + windDirection + "deg)"
        return (
            <div>
            <picture>
                <source srcSet={images['arrow_white']} media="(prefers-color-scheme: dark)" />
                <img src={images['arrow']} className="WindArrow" style={{transform: windArrowRotation}} alt={windDirection+ "deg"}/>
            </picture>
            <WindColor speedms={windSpeed} /> {(windGustSpeed - windSpeed > 5) ? <>(<WindColor speedms={windGustSpeed}/>)</>: ""} m/s
            </div>
        );
    } else {
        return <div>Error in wind data</div>
    }
};

interface WindColorProps {
    speedms: number;
}

const WindColor = (props: WindColorProps): JSX.Element => {
    const s = props.speedms;
    let styleClass = ""
    if (s >= 13.9) { // Hård vind
        styleClass = 'colorOrange';
    } else if (s >= 24.5) { // Storm
        styleClass = 'colorRed';
    }
    return (
        <span className={styleClass}>{s}</span>
    );
};

/**
 * Translate the visibility to METAR format.
 */
export const visibility = (f: TimeSerie): string => {
    const viskm = paramFind(f.parameters, "vis");
    if (viskm === undefined) {
        return "No visibility data";
    }
    const vis = viskm*1000; // km to m.
    const vishundred = Math.floor(vis/100)*100;
    let r = "0000";
    if (vishundred > 9999) { r = "9999" }
    else if (vishundred < 10) { r = "000" + vishundred }
    else if (vishundred < 100) { r = "00" + vishundred } // This branch will never be reached since vishundred are truncated.
    else if (vishundred < 1000) { r = "0" + vishundred }
    else { r = "" + vishundred }
    return r;
}

/**
 * Translate an octas (0-8) to METAR format.
 */
export const cloudCover = (octas: number | undefined): string => {
    if (octas === undefined || octas === null) {
        return "Undefined";
    }
    let cc = "???";
    if (octas < 0) { cc = "???" }
    else if (octas <= 0) { cc = "NSC" }
    else if (octas <= 2) { cc = "FEW" }
    else if (octas <= 4) { cc = "SCT" }
    else if (octas <= 7) { cc = "BKN" }
    else if (octas <= 8) { cc = "OVC" }
    return cc;
}

interface RenderTemperatureProps {
    temp: number | undefined | null;
}

export const RenderTemperature = (props: RenderTemperatureProps): JSX.Element => {
    const t = props.temp;
    if(t === undefined || t === null) {
        return (<></>);
    }
    const tstr = Math.round(t*10)/10;
    let styleClass = ""
    if (t < 3) {
        styleClass = "colorBlue";
    } else if (t >= 30) {
        styleClass = "colorRed";
    } else if (t >= 25) {
        styleClass = "colorOrange";
    }
    return (
        <span>
            <span className={styleClass}>{tstr}</span>℃
        </span>
    );
};

interface feelsLikeTemperatureT {
	(temperature: number | undefined,
     windSpeed: number | undefined,
     humidity: number | undefined)
    : number | null;
}

/**
 * Calculates what the temperature feels like.
 * https://www.smhi.se/polopoly_fs/1.6392!/webb_vindavkylning.pdf
 * https://journals.ametsoc.org/downloadpdf/journals/bams/86/10/bams-86-10-1453.xml
 * @temperature Temperature in Celcius.
 * @windSpeed Wind speed in m/s.
 * @humidity Humidity in percent (%).
 */
export const feelsLikeTemperature: feelsLikeTemperatureT = (temperature, windSpeed, humidity)  => {
    if(typeof temperature !== 'number' || typeof windSpeed !== 'number' || typeof humidity !== 'number') {
        return null;
    }
    if (temperature >= 26.7 && humidity >= 40) { // Heat Index
        return -8.784695 + 1.61139411*temperature + 2.338549*humidity - 0.14611605*temperature*humidity - 1.2308094e-2*temperature**2 - 1.6424828e-2*humidity**2 + 2.211732e-3*temperature**2*humidity + 7.2546e-4*temperature*humidity**2 - 3.582e-6*temperature**2*humidity**2
    } else if (windSpeed < 2) {
        return null;
    } else if (windSpeed > 2 && (-40) < temperature && temperature < 10) { // Wind chill
        return 13.12 + 0.6215*temperature - 13.956*windSpeed**0.16 + 0.48669*temperature*windSpeed**0.16;
    }
    return null;
};

interface PrecipitationRenderProps {
    f: TimeSerie;
	extended?: boolean;
}

export const PrecipitationRender = ( (props: PrecipitationRenderProps): JSX.Element => {
    const precipCat = paramFind(props.f.parameters, "pcat");
    const precipMin = paramFind(props.f.parameters, "pmin");
    const precipMax = paramFind(props.f.parameters, "pmax");
    const precipMean = paramFind(props.f.parameters, "pmean");
    const precipMedian = paramFind(props.f.parameters, "pmedian");
    const precipFrozen = paramFind(props.f.parameters, "spp"); // -9 if no precipitation.
    let precip = "??";
    if (precipCat === 0) { precip = "" }
    else if (precipCat === 1) { precip = "SN" }
    else if (precipCat === 2) { precip = "RASN" }
    else if (precipCat === 3) { precip = "RA" }
    else if (precipCat === 4) { precip = "DZ" }
    else if (precipCat === 5) { precip = "FZRA" }
    else if (precipCat === 6) { precip = "FZDZ" }

    let amount = "";
    if (precip !== "") {
        amount = "("+precipMin+" - ";
        if (props?.extended) {
            amount += "["+precipMean+":"+precipMedian+"] - ";
        }
        amount += precipMax+" mm/h)";
        if ((precipFrozen ?? -1) > 0) {
            amount += " "+precipFrozen+"% FZ";
        }
    } else {
        return <></>
    }
    return (
        <div>
        {precip} {amount !== "" ? amount : ""}
        </div>
)});

export const precipitationTooltip = ( (f: TimeSerie): string => {
    const precipCat = paramFind(f.parameters, "pcat");
    let precip = "??";
    if (precipCat === 0) { precip = "" }
    else if (precipCat === 1) { precip = "Snö" }
    else if (precipCat === 2) { precip = "Snöblandatregn" }
    else if (precipCat === 3) { precip = "Regn" }
    else if (precipCat === 4) { precip = "Duggregn" }
    else if (precipCat === 5) { precip = "Underkylt regn" }
    else if (precipCat === 6) { precip = "Underkylt duggregn" }
    return precip;
});

interface WeatherRowProps {
    f: TimeSerie;
    extended?: boolean;
}

/**
 * Render a row of weather for the given forecast data.
 * Intended to be used in a table.
 */
export const WeatherRow = ( (props: WeatherRowProps): JSX.Element => {
    const a = props.f;
    const viskm = paramFind(a.parameters, "vis");
    const ext = props.extended;
    const validTime = new Date(a.validTime);
    const tsProbValue = paramFind(a.parameters, "tstm")
    const tsProb = (tsProbValue ?? 100) >= 50 ? "TSprob: " + tsProbValue : "";
    const tsProbTooltip = (tsProbValue ?? 100) >= 50 ? { "data-tooltip": "Sannolikhet för åska" } : {};
    const wsymb = paramFind(a.parameters, "Wsymb2") || -1;
    const temperature = paramFind(a.parameters, "t");
    const windSpeed = paramFind(a.parameters, "ws");
    const humidity =  paramFind(a.parameters, "r");
    const tcc_mean = paramFind(a.parameters, "tcc_mean");
    const lcc_mean = paramFind(a.parameters, "lcc_mean");
    const mcc_mean = paramFind(a.parameters, "mcc_mean");
    const hcc_mean = paramFind(a.parameters, "hcc_mean");
    const feelsLike: number | null = feelsLikeTemperature(temperature, windSpeed, humidity);
    const precipTooltipValue = precipitationTooltip(a);
    const precipTooltip = precipTooltipValue !== "" ? { "data-tooltip": precipTooltipValue } : {};
    return (
        <div className="table-row">
            <div className="table-cell">{Time.timeFormat(validTime)}</div>
            <div className="table-cell" data-tooltip={weatherSymbolString(wsymb)}><img src={images[wsymb]} alt={weatherSymbolString(wsymb)} className="WeatherSymbolSmall"/></div>
            <div className="table-cell" data-tooltip="Temperatur (känns som)"><RenderTemperature temp={temperature} /> {feelsLike !== null ?<>(<RenderTemperature temp={feelsLike} />)</> : "" }</div>
            <div className="table-cell" data-tooltip="Vindriktning, vindstyrka (vindbyar)"><Wind f={a}/></div>
            <div className="table-cell" data-tooltip={"Sikt " + viskm +"km"}>{visibility(a)}</div>
            {!ext && <div className="table-cell" data-tooltip={"Molntäcke: " + tcc_mean + "/8"} >{cloudCover(tcc_mean)}</div> }
            {ext && <div className="table-cell" data-tooltip={"Molntäcke (Låg): " + lcc_mean + "/8"}>l: {cloudCover(lcc_mean)}</div> }
            {ext && <div className="table-cell" data-tooltip={"Molntäcke (Medel): " + mcc_mean + "/8"}>m: {cloudCover(mcc_mean)}</div> }
            {ext && <div className="table-cell" data-tooltip={"Molntäcke (Hög): " + hcc_mean + "/8"}>h: {cloudCover(hcc_mean)}</div> }
            <div className="table-cell" {...precipTooltip}><PrecipitationRender f={a} extended={ext}/></div>
            {ext && <div className="table-cell">{paramFind(a.parameters,"r")}% luftfuktighet</div> }
            <div className="table-cell" {...tsProbTooltip}>{tsProb}</div>
            <div className="table-cell" data-tooltip="Lufttryck (hPa)">Q{Math.round(paramFind(a.parameters, "msl") ?? 0)}</div>
        </div>
    );
});

interface WeatherDaysProps {
    f: TimeSerie[];
    flags: FlagDays.FlagDaysData | undefined;
    extended?: boolean;
    sunConfig?: Sun.SunConfig;
}

/**
 * Render the weather for each day.
 */
export const WeatherDays = ( (props: WeatherDaysProps): JSX.Element => {
    const first = props.f.length > 0 ? props.f[0] : null;
    const dateObj = first != null ? new Date(first.validTime) : undefined;
    const date = dateObj !== undefined ? Time.dateFormat(dateObj) : "???";
    const dateLink = dateObj !== undefined ? `/day/${Time.fullDateFormat(dateObj)}` : "/"
    const classNames = props.extended ? "" : "Border Padding"
    return (
        <div className={classNames}>
        <Link to={dateLink}>{date}</Link>
        <FlagDays.RenderFlagDay f={props.flags} d={dateObj}/>
        {props.extended && props.sunConfig && dateObj && <Sun.RenderSunriseSunset day={dateObj} config={props.sunConfig} />}
        <div className="table">
        {props.f.map( (e) => {
            return <WeatherRow key={e.validTime} f={e} extended={props.extended}/>;
        })}
        </div>
        </div>
    );
});

interface TodaysWeatherProps {
    smhiForecast: SmhiForecast | undefined;
    flags: FlagDays.FlagDaysData | undefined;
    sunconfig: Sun.SunConfig;
}

export const TodaysWeather = (props: TodaysWeatherProps): JSX.Element => {
    if (props.smhiForecast === undefined) {
        return (<div>Loading SMHI</div>);
    }
    const smhiForecast: SmhiForecast = props.smhiForecast;
    const approvedT = new Date(smhiForecast.approvedTime);
    const currentWeather: TimeSerie = smhiForecast.timeSeries[0]; //FIXME: dangerous
    const currentWeatherValidTime = new Date(currentWeather.validTime);
    const smhiForecastByDay = groupByDay(smhiForecast.timeSeries); // date: TimeSerie[]
    const currentDayForecast: TimeSerie[] = reduceDataPoints(smhiForecastByDay[Object.keys(smhiForecastByDay).sort()[0]]);
    const currentWsymb = paramFind(currentWeather.parameters, "Wsymb2") || -1;
    const temperature = paramFind(currentWeather.parameters, "t");
    const windSpeed = paramFind(currentWeather.parameters, "ws");
    const humidity =  paramFind(currentWeather.parameters, "r");
    const feelsLike: number | null = feelsLikeTemperature(temperature, windSpeed, humidity);
    return (
        <div className="Margin">
            <div>
                <Link to={`/day/${Time.fullDateFormat(currentWeatherValidTime)}`}>{Time.dateFormat(currentWeatherValidTime)}</Link>
                <FlagDays.RenderFlagDay f={props.flags} d={currentWeatherValidTime}/>
            </div>
            <Sun.RenderSunriseSunset day={currentWeatherValidTime} config={props.sunconfig} />
            <div>Prognos utfärdad: {Time.fullDateFormat(approvedT)} {Time.timeFormat(approvedT)}</div>
            <div className="Flexbox"> {/* Today */}
                <div> {/* Current weather */}
                    <div><b>{Time.timeFormat(currentWeatherValidTime)}</b></div>
                    <div><img src={images[currentWsymb]} alt={weatherSymbolString(currentWsymb)}/></div>
                    <div><span className="BigFontSize"><RenderTemperature temp={paramFind(currentWeather.parameters, "t")} /></span> {feelsLike !== null ?<>känns som <RenderTemperature temp={feelsLike} /></> : "" }
                    </div>
                    <div><Wind f={currentWeather}/></div>
                    <div>{visibility(currentWeather)} {cloudCover(paramFind(currentWeather.parameters, "tcc_mean"))}</div>
                    <div>{paramFind(currentWeather.parameters,"r")}% luftfuktighet</div>
                    <div>Q{Math.round(paramFind(currentWeather.parameters, "msl") ?? 0)}</div>
                    <div><PrecipitationRender f={currentWeather}/></div>
                </div>
                <div className="Margin"> {/* Current day weather forecast */}
                    <div className="table">
                    {currentDayForecast.map( (x) => <WeatherRow key={x.validTime} f={x} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}

interface WeatherForecastProps {
    f: TimeSerie[] | undefined;
    flags: FlagDays.FlagDaysData | undefined;
}

/**
 * Render the weather forecast for all coming days.
 */
export const WeatherForecast = ( (props: WeatherForecastProps): JSX.Element => {
    if (props.f === undefined) {
        return <div>Loading SMHI2</div>
    }
    const smhiForecastByDay = groupByDay(props.f); // date: TimeSerie[]
    return (
        <div className="Grid Margin">
            {Object.keys(smhiForecastByDay).sort().slice(1).map( (dayForecast) => {
                return <WeatherDays key={dayForecast} f={reduceDataPoints(smhiForecastByDay[dayForecast])} flags={props.flags} />
            })}
        </div>
    );
});
