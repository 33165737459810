import './Errors.css';
import axios from 'axios';
import { AxiosError } from 'axios';

interface RenderErrorsProps {
    e: Error[];
}

export const RenderErrors = (props: RenderErrorsProps): JSX.Element => {
    return (
        <div>
        {props.e.map( (x: Error, ix: number) => {
			if (axios.isAxiosError(x)) {
				const ae: AxiosError = (x as AxiosError);
                const resp = typeof ae?.response?.data === 'string' ? ae?.response?.data : "";
				return (
					<div className="Error" key={ae.config.url}>{ae.message}: {resp} ({ae.config.url})</div>
				);
			} else {
				return (
					<div className="Error" key={ix}>{x.message} ({x.name})</div>
				);
			}
        })}
        </div>
    );
};
