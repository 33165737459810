/**
 * This data structure holds the configuration parameters for this site.
 * Tailor it to your needs.
 */

export interface CityConfig {
    smhiForecast: LatLong, // City location in lat/long
    metWarningAreaName: string,
    metarTafAds: string[],// ICAO aerodromes. E.g. ["essp","essp","essa"]
    pollenCity: PollenCity, // Nearest city which provides a pollen forecast
}

export interface LatLong {
    lat: number,
    long: number,
}

export interface PollenCity {
    name: string, // The display name
    query: string, // The name of the link used by pollenrapporten.se for the city
}

export const defaultCity = "Linköping" // Default city when loading page.

export const config: { [key: string]: CityConfig } = {
    "Arvidsjaur": {
        smhiForecast: { lat: 65.591538, long: 19.182129 },
        metWarningAreaName: "Norrbottens län",
        metarTafAds: ["ESNX"],
        pollenCity: { name: "Piteå", query: "pitea" }
    },
    "Göteborg": {
        smhiForecast: { lat: 57.708538, long: 11.973295 },
        metWarningAreaName: "Västra Götalands län",
        metarTafAds: ["ESGG"],
        pollenCity: { name: "Göteborg", query: "goteborg" }
    },
    "Karlskoga": {
        smhiForecast: { lat: 59.326184, long: 14.508133 },
        metWarningAreaName: "Värmlands län",
        metarTafAds: ["ESKK", "ESOK", "ESOE"],
        pollenCity: { name: "Skövde", query: "Skovde" }
    },
    "Kiruna": {
        smhiForecast: { lat: 67.849747, long: 20.235872 },
        metWarningAreaName: "Norrbottens län",
        metarTafAds: ["ESNQ"],
        pollenCity: { name: "Piteå", query: "pitea" }
    },
    "Kolmården": {
        smhiForecast: { lat: 58.662852, long: 16.460497 },
        metWarningAreaName: "Östergötlands län",
        metarTafAds: ["ESSP"],
        pollenCity: { name: "Norrköping", query: "norrkoping" }
    },
    "Linköping": {
        smhiForecast: { lat: 58.410868, long: 15.621582 },
        metWarningAreaName: "Östergötlands län",
        metarTafAds: ["ESSL","ESCF"],
        pollenCity: { name: "Norrköping", query: "norrkoping" }
    },
    "Malmö": {
        smhiForecast: { lat: 55.609384, long: 12.999573 },
        metWarningAreaName: "Skåne län",
        metarTafAds: ["ESMS"],
        pollenCity: { name: "Malmö", query: "malmo" }
    },
    "Motala": {
        smhiForecast: { lat: 58.54282, long: 15.03067 },
        metWarningAreaName: "Östergötlands län",
        metarTafAds: ["ESSL","ESCF"],
        pollenCity: { name: "Norrköping", query: "norrkoping" }
    },
    "Nyköping": {
        smhiForecast: { lat: 58.744596, long: 17.071897 },
        metWarningAreaName: "Södermanlands län",
        metarTafAds: ["ESKN"],
        pollenCity: { name: "Norrköping", query: "norrkoping" }
    },
    "Oxelösund": {
        smhiForecast: { lat: 58.667031, long: 17.100906 },
        metWarningAreaName: "Södermanlands län",
        metarTafAds: ["ESKN"],
        pollenCity: { name: "Norrköping", query: "norrkoping" }
    },
    "Stockholm": {
        smhiForecast: { lat: 59.332292, long: 18.064013 },
        metWarningAreaName: "Stockholms län",
        metarTafAds: ["ESSB"],
        pollenCity: { name: "Stockholm", query: "stockholm" }
    },
    "Sturefors": {
        smhiForecast: { lat: 58.354955, long: 15.699892 },
        metWarningAreaName: "Östergötlands län",
        metarTafAds: ["ESSL","ESCF"],
        pollenCity: { name: "Norrköping", query: "norrkoping" }
    },
    "Vimmerby": {
        smhiForecast: { lat: 57.674327, long: 15.843701 },
        metWarningAreaName: "Kalmar län",
        metarTafAds: ["ESSW"],
        pollenCity: { name: "Västervik", query: "vastervik" }
    },
    "Vrena": {
        smhiForecast: { lat: 58.860999, long: 16.700635 },
        metWarningAreaName: "Södermanlands län",
        metarTafAds: ["ESKN"],
        pollenCity: { name: "Norrköping", query: "norrkoping" }
    },
    "Västerås": {
        smhiForecast: { lat: 59.608739, long: 16.552277 },
        metWarningAreaName: "Västmanlands län",
        metarTafAds: ["ESOW"],
        pollenCity: { name: "Eskilstuna", query: "eskilstuna" }
    },
}

