import './SMHI.css'
import './Pollen.css'

export interface City {
    name: string,
    query: string,
}

type URL = string;

export interface PollenRequests {
    regions: Regions | undefined;
    forecast: Forecast | undefined;
    pollen_types: PollenTypes | undefined;
    pollen_level: PollenLevel | undefined;
}

export interface Regions {
    items: [RegionItem];
}

export interface RegionItem {
    id: string;
    name: string;
    forecasts: URL;
}

export interface Forecast {
    _meta: ForecastMeta;
    items: [ForecastItem];
}

interface ForecastMeta {
    totalRecords: number;
    offset: number;
    limit: number;
    count: number;
}

interface ForecastItem {
    id: string;
    regionId: string;
    startDate: string;
    endDate: string;
    text: string;
    levelSeries: [LevelSeries];
}

interface LevelSeries {
    pollenId: string;
    level: number;
    time: string;
}

export interface PollenTypes {
    items: [PollenTypesItem];
}

interface PollenTypesItem {
    id: string;
    name: string;
    forecast: string;
}

export interface PollenLevel {
    items: [PollenLevelItem];
}

interface PollenLevelItem {
    level: number;
    name: string;
}

interface RenderPollenProps {
    p: PollenRequests;
    city: City;
}
export const RenderPollen = (props: RenderPollenProps): JSX.Element => {
    if (props.p.forecast === undefined || props.p.pollen_level === undefined ||
        props.p.pollen_types === undefined || props.p.forecast.items.length !== 1) {
        return (
            <div>Loading Pollen</div>
        );
    }
    const today = new Date();
    const items = props.p.forecast.items[0];
    let todays_level = items.levelSeries.filter( (x: LevelSeries) => {
        const xt = new Date(x.time);
        if (today.getFullYear() === xt.getFullYear() &&
            today.getMonth() === xt.getMonth() &&
            today.getDate() === xt.getDate() &&
            x.level > 0) {
                return true;
            } else {
                return false;
            }
    });
    todays_level.sort( (a, b) => b.level - a.level);
    const pollen_translated = todays_level.map( (x: LevelSeries) => {
        const pollen_type = props.p.pollen_types?.items.find( (y) => y.id === x.pollenId)?.name;
        const pollen_level = props.p.pollen_level?.items.find( (y) => y.level === x.level)?.name;
        const res = {
            pollen: pollen_type,
            level: pollen_level,
        }
        return res;
    });
    const items_text_space_ix = items.text.indexOf(" ", 32);
    return (
        <div className='Margin'>
            <div className='PollenGrid'>
                <p>Pollenprognos för <a href={`https://pollenrapporten.se/${props.city.query}`}>{props.city.name}</a> idag:</p>
                <div className='table'>
                    {pollen_translated.map( (x) => {
                        return (
                            <div className='table-row' key={x.pollen}>
                                <div className='table-cell'>{x.pollen}</div>
                                <div className='table-cell'>{x.level}</div>
                            </div>
                        );
                    })}
                </div>
                <details>
                    <summary>{items.text.slice(0, items_text_space_ix)}</summary>
                    <p>{items.text.slice(items_text_space_ix)}</p>
                </details>
            </div>
        </div>
    );
}