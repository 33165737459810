import React from 'react';
import './App.css';
import Weather from './components/Weather';

function App(): JSX.Element {
  const today = new Date();
  if (isChristmas(today)) {
    document.body.classList.add('redbg');
  }
  return (
    <div>
      <header>
      <Weather isSummer={isSummer(today)}/>
      </header>
    </div>
  );
}

/**
 * Returns true if it is summer.
 */
const isSummer = (today: Date): boolean => {
    const month = today.getMonth() + 1;
    if (3 <= month && month <= 9) {
        return true;
    } else {
        return false;
    }
}

/**
 * Returns true if it is Christmas.
 */
const isChristmas = (today: Date): boolean => {
    const month = today.getMonth() + 1;
    const date = today.getDate();
    if (month === 12 && 22 <= date) {
        return true;
    } else {
        return false;
    }
}

export default App;
