import { config } from './Config';
import './CitySelector.css';

export interface OnCityChangeE {
    (newCity: string): void;
}

interface RenderCitySelectorProps {
    c: string;
    onCityChange: OnCityChangeE;
}

export const RenderCitySelector = (props: RenderCitySelectorProps): JSX.Element => {
    const cities = Object.keys(config).sort();
    return (
        <div className="Margin">
            <select name="city" defaultValue={props.c} onChange={(e) => props.onCityChange(e.currentTarget.value)} >
                { cities.map( (ct: string) => {
                    return (
                        <option key={ct} value={ct}>{ct}</option>
                    );
                })}
            </select>
        </div>
    );
};
