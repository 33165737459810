import React, { useState } from 'react';
import { images } from '../images/images';
import './Image.css';

/**
 * Render the UV index forecast.
 */
export const RenderUvIndex = ( (): JSX.Element => {
    const [fullSize, setFullSize] = useState<{w: number, h: number} | null>(null);
    const [isFullSize, setIsFullSize] = useState<boolean>(false);
    let sizeh = 70; // fullSize.h/3
    if (fullSize != null) {
        sizeh = isFullSize ? fullSize.h : fullSize.h/3;
    }
    const infoClass = isFullSize ? "Transition" : "Transition Hidden";
    const extraInfo = !isFullSize ?  <div className={infoClass}></div> :
            <div className={infoClass}>
                <div className="WhiteBg"><img src={images["global_uvi_legend"]} width="100%" alt="UV Index Legend"/></div>
                <div>Sun protection is required when the UV index is 3 or higher.</div>
            </div>
    return (
        <div className="Margin">
            <img className="Transition" src="https://cdn.fmi.fi/apps/global-ultraviolet-index/plot.php?location=stockholm&lang=en&day=0" height={sizeh} width="auto" alt="UV Index Forecast" onClick={() =>        setIsFullSize(!isFullSize)} onLoad={(e) => setFullSize({w: e.currentTarget.naturalWidth, h: e.currentTarget.naturalHeight})} />
            {extraInfo}
        </div>
    )
});
