import React from 'react';
import { BrowserRouter as Router, Route, Link, RouteComponentProps } from 'react-router-dom';
import { config, defaultCity } from './Config';
import './Weather.css';
import * as Errors from './Errors';
import * as CitySelector from './CitySelector';
import * as SMHI from './SMHI';
import * as ARO from './ARO';
import * as MetWarnings from './MetWarnings';
import * as Pollen from './Pollen';
import * as UvIndex from './UvIndex';
import * as NSWC from './NSWC';
import * as FlagDays from './FlagDays';
import * as Sun from './Sun';
import * as Settings from './Settings';
import axios from 'axios';
let flagdaysfile = require('./FlagDays.json');

const cityKey = "last_selected_city"

interface WeatherState {
    errors: Error[];
    city: string;
    smhiForecast: SMHI.SmhiForecast | undefined;
    metar: ARO.Metar | undefined;
    taf: ARO.Taf | undefined;
    metwarnings: MetWarnings.MetWarnings | undefined;
    pollen: Pollen.PollenRequests;
    flagdays: FlagDays.FlagDaysData;
    tooltipOn: boolean;
}

interface WeatherProps {
    isSummer: boolean;
}

class Weather extends React.Component<WeatherProps> {
    state: WeatherState = {
        errors: [],
        city: "", // This will be initialized when component mounts. First render will be handled by the city is not found in the config.
        smhiForecast: undefined,
        metar: undefined,
        taf: undefined,
        metwarnings: undefined,
        pollen: {
            regions: undefined,
            forecast: undefined,
            pollen_types: undefined,
            pollen_level: undefined,
        },
        flagdays: {flagdays: flagdaysfile},
        tooltipOn: false,
    }
    componentDidMount(): void {
        const last_selected_city = window.localStorage.getItem(cityKey);
        const city = last_selected_city || defaultCity;
        this.setState({city});
        this.fetchData(city);
    }
    resetState(): void {
        let pollen = this.state.pollen;
        pollen.forecast = undefined;
        const emptyState = {
            errors: [],
            smhiForecast: undefined,
            metar: undefined,
            taf: undefined,
            metwarnings: undefined,
            pollen,
        }
        this.setState(emptyState);
    }
    fetchData(cityName: string): void {
        const cityConf = config[cityName];
        if (cityConf === undefined) {
            const err = {
                errors: this.state.errors,
            }
            err.errors.push(new Error(`Unknown city: ${cityName}`));
            this.setState(err);
            return;
        }
        axios.get("https://opendata-download-metfcst.smhi.se/api/category/pmp3g/version/2/geotype/point/lon/" + cityConf.smhiForecast.long + "/lat/" + cityConf.smhiForecast.lat + "/data.json")
            .then( (res: {data: SMHI.SmhiForecast}) => {
                const r = {
                    smhiForecast: res.data,
                }
                this.setState(r);
                return res.data;
            })
            .catch( (e) => {
                const err = {
                    errors: this.state.errors,
                }
                const ee = e instanceof Error ? e : new Error(e);
                err.errors.push(ee);
                this.setState(err);
            })
        axios.get("https://metarproxy.azurewebsites.net/api/metarproxy?ids="+cityConf.metarTafAds.join(","))
            .then( (res: {data: string}) => {
                let metar = res.data.split("\n")
                const r = {
                    metar,
                }
                this.setState(r);
            })
            .catch( (e) => {
                const err = {
                    errors: this.state.errors,
                }
                const ee = e instanceof Error ? e : new Error(e);
                err.errors.push(ee);
                this.setState(err);
            })
        axios.get("https://metarproxy.azurewebsites.net/api/tafproxy?ids="+cityConf.metarTafAds.join(","))
            .then( (res: {data: string}) => {
                let taf = res.data.split("\n").join(" ").replace(/^TAF/, "").split("TAF")
                const r = {
                    taf,
                }
                this.setState(r);
            })
            .catch( (e) => {
                const err = {
                    errors: this.state.errors,
                }
                const ee = e instanceof Error ? e : new Error(e);
                err.errors.push(ee);
                this.setState(err);
            })
        axios.get("https://opendata-download-warnings.smhi.se/ibww/api/version/1/warning.json")
            .then( (res: {data: MetWarnings.MetWarnings}) => {
                const r = {
                    metwarnings: res.data,
                }
                this.setState(r);
            })
            .catch( (e) => {
                const err = {
                    errors: this.state.errors,
                }
                const ee = e instanceof Error ? e : new Error(e);
                err.errors.push(ee);
                this.setState(err);
            })
        if (this.props.isSummer) {
            try {
                if (this.state.pollen.regions === undefined) {
                    axios.get("https://api.pollenrapporten.se/v1/regions")
                        .then( (res: {data: Pollen.Regions}) => {
                            let pollen = this.state.pollen;
                            pollen.regions = res.data;
                            this.setState(pollen);
                            let region = res.data.items.find( i => i.name === cityConf.pollenCity.name );
                            if (region !== undefined) {
                                this.fetchPollenForecast(region.forecasts);
                            } else {
                                throw Error("Unable to find pollen region: `cityConf.pollenCity.name`");
                            }
                        })
                        .catch( (e) => {
                            const ee = e instanceof Error ? e : new Error(e);
                            throw ee;
                        })
                } else {
                        let region = this.state.pollen.regions.items.find( i => i.name === cityConf.pollenCity.name );
                        if (region !== undefined) {
                            this.fetchPollenForecast(region.forecasts);
                        } else {
                            throw Error("Unable to find pollen region: `cityConf.pollenCity.name`");
                        }
                }
                if (this.state.pollen.pollen_level === undefined) {
                    axios.get("https://api.pollenrapporten.se/v1/pollen-level-definitions")
                        .then( (res: {data: Pollen.PollenLevel}) => {
                            let pollen = this.state.pollen;
                            pollen.pollen_level = res.data;
                            this.setState(pollen);
                        })
                        .catch( (e) => {
                            const ee = e instanceof Error ? e : new Error(e);
                            throw ee;
                        })
                }
                if (this.state.pollen.pollen_types === undefined) {
                    axios.get("https://api.pollenrapporten.se/v1/pollen-types")
                        .then( (res: {data: Pollen.PollenTypes}) => {
                            let pollen = this.state.pollen;
                            pollen.pollen_types = res.data;
                            this.setState(pollen);
                        })
                        .catch( (e) => {
                            const ee = e instanceof Error ? e : new Error(e);
                            throw ee;
                        })
                }
            } catch (e: any) {
                    const err = {
                        errors: this.state.errors,
                    }
                    const ee = e instanceof Error ? e : new Error(e);
                    err.errors.push(ee);
                    this.setState(err);
            }
        }
    }

    fetchPollenForecast(url: string): void {
        axios.get(url)
            .then( (res: {data: Pollen.Forecast}) => {
                let pollen = this.state.pollen;
                pollen.forecast = res.data;
                this.setState(pollen);
            })
            .catch( (e) => {
                const err = {
                    errors: this.state.errors,
                }
                const ee = e instanceof Error ? e : new Error(e);
                err.errors.push(ee);
                this.setState(err);
            })

    }

    onCityChange: CitySelector.OnCityChangeE = (newCity: string) => {
        this.setState({city: newCity});
        this.resetState();
        this.fetchData(newCity);
        window.localStorage.setItem(cityKey, newCity);
    }

    onTooltipChange: Settings.OnTooltipChangeE = (tooltipOn: boolean) => {
        this.setState({tooltipOn: tooltipOn});
    }

    render(): JSX.Element {
        return (
          <Router>
            <Route exact path="/" render={ () => (<WeekForecast s={this.state} isSummer={this.props.isSummer} onCityChange={this.onCityChange} onTooltipChange={this.onTooltipChange}/>) } />
            <Route path="/day/:date" render={ (date) => (<DayForecast s={this.state} d={date} onCityChange={this.onCityChange}/> ) } />
          </Router>
        );
    }
}

interface WeekForecastProps {
    s: WeatherState;
    isSummer: boolean;
    onCityChange: CitySelector.OnCityChangeE;
    onTooltipChange: Settings.OnTooltipChangeE;
}

const WeekForecast = (props: WeekForecastProps): JSX.Element => {
    const { s, isSummer } = props;
    const cityConf = config[s.city];
    const sunconfig: Sun.SunConfig = {lat: cityConf?.smhiForecast.lat, long: cityConf?.smhiForecast.long};
    const tooltips = s.tooltipOn ? "TooltipON" : "TooltipOFF";
    if (cityConf === undefined) {
        return <UnknownCity s={s} onCityChange={props.onCityChange}/>
    }
    return (
        <div className={tooltips}>
            <Errors.RenderErrors e={s.errors} />
            <MetWarnings.RenderMetWarnings m={s.metwarnings} areaName={cityConf.metWarningAreaName}/>
            <div className="Flexbox space-between center-items Margin">
                <CitySelector.RenderCitySelector c={s.city} onCityChange={props.onCityChange} />
                <Settings.Settings onTooltipChange={props.onTooltipChange}/>
            </div>
            <SMHI.TodaysWeather smhiForecast={s.smhiForecast} flags={s.flagdays} sunconfig={sunconfig}/>
            <div className="Flexbox">
                {isSummer ? <Pollen.RenderPollen p={s.pollen} city={cityConf.pollenCity}/> : <></> }
                <NSWC.RenderNSWC />
                {isSummer ? <UvIndex.RenderUvIndex /> : <></> }
            </div>
            <ARO.RenderMetarTaf m={s.metar} t={{type: "METAR"}} />
            <ARO.RenderMetarTaf m={s.taf} t={{type: "TAF"}} />
            <SMHI.WeatherForecast f={s.smhiForecast?.timeSeries} flags={s.flagdays}/>
            <p className="Margin">Källa: smhi.se, fmi.fi, aro.lfv.se, aviationweather.gov, Palynologiska laboratoriet vid Naturhistoriska riksmuseet (pollenrapporten.se)</p>
        </div>
    );
}

interface RouterProps {
    date: string;
}

interface DayForecastProps {
    s: WeatherState;
    d: RouteComponentProps<RouterProps>;
    onCityChange: CitySelector.OnCityChangeE;
}

const DayForecast = (props: DayForecastProps): JSX.Element => {
    const { s, d } = props;
    const { date } = d.match.params;
    const cityConf = config[s.city];
    if (cityConf === undefined) {
        return <UnknownCity s={s} onCityChange={props.onCityChange}/>
    }
    const sunConfig: Sun.SunConfig = {lat: cityConf.smhiForecast.lat, long: cityConf.smhiForecast.long};
    const tooltips = s.tooltipOn ? "TooltipON" : "TooltipOFF";
    if (s.smhiForecast !== undefined) {
        const smhi = SMHI.groupByDay(s.smhiForecast.timeSeries)[date]
        if (smhi !== undefined) {
            return (
                <div className={tooltips}>
                    <CitySelector.RenderCitySelector c={s.city} onCityChange={props.onCityChange} />
                    <SMHI.WeatherDays f={smhi} flags={s.flagdays} extended={true} sunConfig={sunConfig}/>
                    <Link to="/">Tillbaka</Link>
                </div>
            );
        } else {
            return (
                <div>
                    <p>Date not found: {date}</p>
                    <Link to="/">Tillbaka</Link>
                </div>
            );
        }
    } else {
        return (
            <div>
                <p>Loading...</p>
                <Link to="/">Tillbaka</Link>
            </div>
        );
    }
}

interface UnknownCityProps {
    s: WeatherState;
    onCityChange: CitySelector.OnCityChangeE;
}

const UnknownCity = (props: UnknownCityProps): JSX.Element => {
    return (
            <div>
                <Errors.RenderErrors e={props.s.errors} />
                <div className="Flexbox space-between center-items Margin">
                    <CitySelector.RenderCitySelector c={props.s.city} onCityChange={props.onCityChange} />
                </div>
            </div>
    );
}

export default Weather;
