import w1 from './1.png';
import w2 from './2.png';
import w3 from './3.png';
import w4 from './4.png';
import w5 from './5.png';
import w6 from './6.png';
import w7 from './7.png';
import w8 from './8.png';
import w9 from './9.png';
import w10 from './10.png';
import w11 from './11.png';
import w12 from './12.png';
import w13 from './13.png';
import w14 from './14.png';
import w15 from './15.png';
import w16 from './16.png';
import w17 from './17.png';
import w18 from './18.png';
import w19 from './19.png';
import w20 from './20.png';
import w21 from './21.png';
import w22 from './22.png';
import w23 from './23.png';
import w24 from './24.png';
import w25 from './25.png';
import w26 from './26.png';
import w27 from './27.png';
import arrow from './arrow.svg';
import arrow_white from './arrow_white.svg';
import cogwheel from './cogwheel.svg';
import flag from './flag.svg';
import global_uvi_legend from './global_uvi_legend.png';
import lunar_first_quarter from './lunar_first_quarter.svg';
import lunar_full_moon from './lunar_full_moon.svg';
import lunar_last_quarter from './lunar_last_quarter.svg';
import lunar_new_moon from './lunar_new_moon.svg';
import sunrise from './sunrise.svg';
import sunset from './sunset.svg';

export const images: {[key: string]: string} = {
    '1': w1,
    '2': w2,
    '3': w3,
    '4': w4,
    '5': w5,
    '6': w6,
    '7': w7,
    '8': w8,
    '9': w9,
    '10': w10,
    '11': w11,
    '12': w12,
    '13': w13,
    '14': w14,
    '15': w15,
    '16': w16,
    '17': w17,
    '18': w18,
    '19': w19,
    '20': w20,
    '21': w21,
    '22': w22,
    '23': w23,
    '24': w24,
    '25': w25,
    '26': w26,
    '27': w27,
    'arrow': arrow,
    'arrow_white': arrow_white,
    'cogwheel': cogwheel,
    'flag': flag,
    'global_uvi_legend': global_uvi_legend,
    'lunar_first_quarter': lunar_first_quarter,
    'lunar_full_moon': lunar_full_moon,
    'lunar_last_quarter': lunar_last_quarter,
    'lunar_new_moon': lunar_new_moon,
    'sunrise': sunrise,
    'sunset': sunset,
}
