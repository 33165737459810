import './ARO.css'
export type Metar = string[];
export type Taf = string[];

export interface MetarTaf {
    type: string; // METAR | TAF
}

interface RenderMetarTafProps {
    m: Metar | undefined;
    t: MetarTaf;
}

export const RenderMetarTaf = (props: RenderMetarTafProps): JSX.Element => {
    if (props.m === undefined) {
        return (
            <div>Loading {props.t.type}</div>
        );
    }
    return (
        <div className="Margin">
        {props.m.map( (x: string) => {
            return (<p key={x} className="MetarMargin">{x}</p>);
        })}
        </div>
    );
};
