/** This component calculates the sunrise and sunset for a given day and latitude.
* The formula is from
* https://www.astroinfo.se/solens-upp-och-nedgangar/
* and
* https://en.wikipedia.org/wiki/Position_of_the_Sun#Calculations
* Other good resources are:
* https://en.wikipedia.org/wiki/Sunrise_equation
*/

import './Sun.css';
import * as Time from './Time';
import { images } from '../images/images';

export interface SunConfig {
    lat: number;
    long: number;
}

export type SunriseSunset = { type: "regular", date: Date }
                          | { type: "alwaysBelowHorizon" }
                          | { type: "alwaysAboveHorizon" }

type Degrees = number;
type Radians = number;
type Seconds = number;

interface RenderSunriseSunsetProps {
    day: Date;
    config: SunConfig;
}

export const RenderSunriseSunset = (props: RenderSunriseSunsetProps): JSX.Element => {
    const sr = sunrise(props.day, props.config);
    const ss = sunset(props.day, props.config);
    if ((sr.type === "alwaysBelowHorizon") || (ss.type === "alwaysBelowHorizon")) {
        return (
            <img src={images['sunset']} className="SunriseImg" alt="Sunset" title="Sunset"/>
        );
    }
    if ((sr.type === "alwaysAboveHorizon") || (ss.type === "alwaysAboveHorizon")) {
        return (
            <img src={images['sunrise']} className="SunriseImg" alt="Sunrise" title="Sunrise"/>
        );
    }
    return (
        <div>
            <img src={images['sunrise']} className="SunriseImg" alt="Sunrise" title="Sunrise"/>
            {Time.timeFormat(sr.date)}
            <img src={images['sunset']} className="SunriseImg" alt="Sunset" title="Sunset"/>
            {Time.timeFormat(ss.date)}
        </div>
    );
};

export const sunrise = ( (day: Date, config: SunConfig): SunriseSunset => {
    return hourAngleToTime(hourAngle(config.lat, declination(day)), -1);
});

export const sunset = ( (day: Date, config: SunConfig): SunriseSunset => {
    return hourAngleToTime(hourAngle(config.lat, declination(day)), 1);
});

/**
 * Only the time is valid in the returned Date object.
 * Discard the date.
 * riseset: -1 for sunrise or 1 for sunset.
 */
const hourAngleToTime = ( (ha:HourAngle, riseset: number): SunriseSunset => {
    if(ha.type === "BelowHorizon") {
        return { type: "alwaysBelowHorizon" };
    } else if(ha.type === "AboveHorizon") {
        return { type: "alwaysAboveHorizon" };
    } else {
        const n = new Date(noon());
        n.setUTCSeconds(n.getUTCSeconds() + deg2seconds(ha.value * riseset))
        return { type: "regular", date: n };
    }
});

type HourAngle = { type: "Degrees", value: Degrees }
               | { type: "BelowHorizon" }
               | { type: "AboveHorizon" }

/** Returns the Hour Angle of the sun in degrees for a given latitude and declination.
* All angles in degrees.
* Sunrise equation:
* cos w = (-0.01454 - sin lat * sin dec)/(cos lat * cos dec)
*   where
*      w = hour angle at sunrise (when negative value is taken) or sunset (when positive value is taken).
*      0.01454 = A value to correct for refraction and make sure the sun really has set.
*/
const hourAngle = ( (lat: Degrees, dec: Degrees): HourAngle => {
    const latitude = deg2rad(lat)
    const d = deg2rad(dec)
    const ha = rad2deg(Math.acos ((-0.01454 - ((Math.sin(latitude)) * (Math.sin(d))))/((Math.cos(latitude)) * (Math.cos(d)))))
    if (isNaN(ha) && dec < 0.0) {
        return { type: "BelowHorizon" };
    } else if (isNaN(ha) && dec > 0.0) {
        return { type: "AboveHorizon" };
    } else {
        return { type: "Degrees", value: ha };
    }
});

/**
 * Calculates the declination of the sun for a given day.
 */
const declination = ( (day: Date): Degrees => {
    const daymidnight: number = +new Date(Date.UTC(day.getUTCFullYear(), day.getUTCMonth(), day.getUTCDate())); // + to make it a number.
    const first: number = +new Date(Date.UTC(day.getUTCFullYear(), 0, 1, 0, 0)); // 1 jan
    const dayOfYear = (daymidnight - first)/1000/60/60/24;
    const dec = rad2deg(-Math.asin(0.39779*Math.cos(deg2rad(0.98565)*(dayOfYear+10) + deg2rad(1.914)*Math.sin(deg2rad(0.98565)*(dayOfYear-2)))))
    return dec;
});

/**
 * To be used as new Date(noon());
 */
const noon = () => {
    const today = new Date();
    if (Time.isDST(today)) {
        return "2021-08-04T13:00:00";
    } else {
        return "2021-08-04T12:00:00";
    }
}

/**
 * Convert from hour angle to hour diff from noon.
 */
const deg2seconds = ( (d: Degrees): Seconds => {
    return d*60*60/(360/24);
});

const deg2rad = ( (d: Degrees): Radians => {
    return d*Math.PI/180.0;
});

const rad2deg = ( (r: Radians): Degrees => {
    return r*180.0/Math.PI;
});

