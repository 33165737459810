import './MetWarnings.css';
import * as Time from './Time';
/**
 * https://opendata.smhi.se/apidocs/IBWwarnings/index.html
 */
export type MetWarnings = MetWarning[];

export interface MetWarning {
    id: number,
    normalProbability: boolean,
    event: Event,
    areaName?: SvEn,
    descriptions?: string[],
    warningAreas: WarningArea[],
}

export interface Event {
    sv: string,
    en: string,
    code: string,
    mhoClassification: SvEnCode,
}

export interface WarningArea {
    id: number,
    approximateStart: string,
    approximateEnd?: string,
    published: string,
    normalProbability?: boolean,
    areaName?: SvEn,
    warningLevel: SvEnCode,
    eventDescription: SvEnCode,
    affectedAreas: AffectedAreas[],
    descriptions?: Description[],
    area: Area,
}

export interface AffectedAreas {
    id: number,
    sv: string,
    en: string,
}

export interface Description {
    title: SvEnCode,
    text: SvEn,
}

export interface Area {
    crs?: CRS,
    type: string,
    geometry: Geometry,
    properties?: Properties,
}

export interface CRS {
    type: string,
    properties: CRSProperties,
}

export interface CRSProperties {
    name: string,
}

export interface Geometry {
    type: string,
    coordinates: LatLong[][],
}

export type LatLong = number[];

export interface Properties {
    en: string,
    id: number,
    sv: string,
    code: string,
    tovepolygon: boolean,
}

export interface SvEnCode {
    sv: string,
    en: string,
    code: string,
}

export interface SvEn {
    sv: string,
    en?: string,
}

export interface MyWarningArea {
    id: number;
    warningLevelCode: string; // Warning code
    approximateStart: string;
    approximateEnd?: string,
    eventDescription: SvEnCode,
    areaName?: SvEn,
    warningAreaDescriptions?: Description[],
}

const warningLevel2CSS = ( (wl: string): string => {
    switch(wl) {
        case "MESSAGE": return "MetWarningMessage";
        case "RED": return "MetWarningRed";
        case "ORANGE": return "MetWarningOrange";
        case "YELLOW": return "MetWarningYellow";
    }
    return "MetWarningsRed";
})

interface RenderMetWarningsProps {
    m: MetWarnings | undefined;
    areaName: string;
}

export const RenderMetWarnings = (props: RenderMetWarningsProps): JSX.Element => {
    if (props.m === undefined) {
        return (
            <div>Loading MetWarnings</div>
        );
    }
    const filtered = filterAndSortAreas(props.m, props.areaName);
    return (
        <div>
         {filtered.map( (x: MyWarningArea) => {
            const desc = x.warningAreaDescriptions?.map( (d) => {
                return (
                   <div key={d.title.sv}>
                       <i>{d.title.sv}:</i> <span>{d.text.sv}</span>
                   </div>
                )});
            const start = new Date(x.approximateStart);
            const startStr = "" + Time.dateFormat(start) + " " + Time.timeFormat(start);
            const end = x.approximateEnd ? new Date(x.approximateEnd) : undefined;
            const endStr = end ? ("" + Time.dateFormat(end) + " " + Time.timeFormat(end)) : "?";
            return (
                <div className={warningLevel2CSS(x.warningLevelCode)} key={x.id}>
                    <details>
                        <summary>
                            {fixPuctuation(x.eventDescription.sv)}! {fixPuctuation(x.areaName?.sv)}. {startStr} - {endStr}
                        </summary>
                        {desc}
                    </details>
                </div>
            );
          })}
        </div>
    );
}

/**
 * Filter and sort the MetWarnings.
 */
export const filterAndSortAreas = (m: MetWarning[], areaName: string): MyWarningArea[] => {
    const filtered = filterAffectedAreas(m, areaName);
    const warningAreas = createWarningAreas(filtered);
    const sortedWarningAreas = sortWarningAreas(warningAreas);
    return sortedWarningAreas;
}

/**
 * Return only affected areas matching the areaName.
 */
const filterAffectedAreas = (m: MetWarning[], areaName: string): MetWarning[] => {
    const filtered: MetWarning[] = [];
    m.forEach( (a: MetWarning) => {
        const newWarningAreas: WarningArea[] = [];
        a.warningAreas.forEach( (wa: WarningArea) => {
            let isAffected = false;
            wa.affectedAreas.forEach( (aa: AffectedAreas) => {
                if (aa.sv === areaName) {
                    isAffected = true;
                    return 0;
                }
                return 0;
            });
            if (isAffected) {
                newWarningAreas.push(wa);
            }
            return 0;
        });
        if (newWarningAreas.length > 0) {
            a.warningAreas = newWarningAreas;
            filtered.push(a);
        }
        return 0;
    });
    return filtered;
}

/**
 * Transform from MetWarnings to MyWarningArea.
 */
const createWarningAreas = (m: MetWarning[]): MyWarningArea[] => {
    const warningAreas: MyWarningArea[] = [];
    m.forEach( (f: MetWarning) => {
        f.warningAreas.forEach( (x: WarningArea) => {
            const wa = {
                warningLevelCode: x.warningLevel.code,
                id: x.id,
                approximateStart: x.approximateStart,
                approximateEnd: x.approximateEnd,
                eventDescription: x.eventDescription,
                areaName: x.areaName,
                warningAreaDescriptions: x.descriptions,
            };
            warningAreas.push(wa);
        })
    });
    return warningAreas;
}

/**
 * Sort the warning areas based on warning level. RED being the heights warning.
 */
const sortWarningAreas = (warningAreas: MyWarningArea[]): MyWarningArea[] => {
    const order = ["RED", "ORANGE", "YELLOW"];
    warningAreas.sort( (a:MyWarningArea, b:MyWarningArea) => {
        const aWarningCode = a.warningLevelCode;
        const bWarningCode = b.warningLevelCode;
        for (let i = 0; i < order.length; i++) {
            if ((aWarningCode === order[i]) && (bWarningCode !== order[i])) {
                return -1;
            } else if ((aWarningCode !== order[i]) && (bWarningCode === order[i])) {
                return 1;
            }
        }
        return 0;
    });
    return warningAreas;
}

/**
 * Removes trailing punctuation.
 */
const fixPuctuation = (str: string | undefined) => {
    if (str) {
        return str.replace(/\.$/, "");
    } else {
        return undefined;
    }
}
