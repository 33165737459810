import React from 'react';
import './Settings.css'
import { images } from '../images/images';
import * as Toggle from './Toggle';

const toolTipKey = "tooltip";

export interface OnTooltipChangeE {
    (tooltipOn: boolean): void;
}

interface SettingsState {
    isOpen: boolean
    tooltipOn: boolean;
}

interface SettingsProps {
    onTooltipChange: OnTooltipChangeE;
}

export class Settings extends React.Component<SettingsProps> {
    state: SettingsState = {
        isOpen: false,
        tooltipOn: false,
    }
    componentDidMount(): void {
        const tooltipSetting = ("true" === window.localStorage.getItem(toolTipKey));
        this.props.onTooltipChange(tooltipSetting);
        this.setState({tooltipOn: tooltipSetting});
    }
    render(): JSX.Element {
        const clickAreaToClose = this.state.isOpen ? "CloseClickArea" : "";
        const cogWheelRotation = this.state.isOpen ? "Rotate" : "";
        const settingsDialogOpen = this.state.isOpen ? "SettingsDialog SettingsDialogOpened" : "SettingsDialog SettingsDialogClosed";
        return (
            <div className="Settings">
                <div className={clickAreaToClose} onClick={() => {this.toggleOpenSettings()}}></div>
                <img className={cogWheelRotation} src={images['cogwheel']} alt="Settings" onClick={() => {this.toggleOpenSettings()}}/>
                <div className={settingsDialogOpen}>
                    <div className="SettingsRow">
                        <input type="checkbox" name="Tooltips" id="tooltipsCheckbox" checked={this.state.tooltipOn} onChange={() => {this.tooltipOnChange()}}/>
                        <label htmlFor="tooltipsCheckbox">Tooltips</label>
                        <Toggle.RenderToggle isOn={this.state.tooltipOn} onToggleClick={() => {this.tooltipOnChange()}}/>
                    </div>
                    <hr/>
                    <p>This setting will be stored on your device.</p>
                </div>
            </div>
        );
    }
    toggleOpenSettings(): void {
        const open = {
            isOpen: !this.state.isOpen,
        }
        this.setState(open);
    }
    tooltipOnChange(): void {
        const tooltip = {
            tooltipOn: !this.state.tooltipOn,
        }
        window.localStorage.setItem(toolTipKey, tooltip.tooltipOn.toString());
        this.setState(tooltip);
        this.props.onTooltipChange(tooltip.tooltipOn);
    }
}

export default Settings;
