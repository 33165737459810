import React, { useState } from 'react';
import './FlagDays.css';
import * as Time from './Time';
import { images } from '../images/images';

export interface FlagDaysData {
    flagdays: {[k: string]: string[]}; // E.g. "2021-07-14": ["Flag 1", "Flag 2"]
}

interface RenderFlagDayProps {
    f: FlagDaysData | undefined;
    d: Date | undefined;
}

export const RenderFlagDay = (props: RenderFlagDayProps): JSX.Element => {
    const [showFlagText, setShowFlagText] = useState(false);
    const f = props.f;
    const d = props.d;
    if (f === undefined || d === undefined) {
        return <></>
    } else {
        if (f.flagdays[Time.fullDateFormat(d)] !== undefined) {
            return (
                <>
                {f.flagdays[Time.fullDateFormat(d)].map( (x) => {
                    return (
                        <div className="FlagDiv" onClick={() => setShowFlagText(!showFlagText)}>
                            <img key={x} className="Flag" src={images['flag']} alt={x} title={x}/>
                            {showFlagText && <p>{x}</p>}
                        </div>
                    )
                })}
                </>
            );
        } else {
            return <></>
        }
    }
}
