import React, { useState } from 'react';
import './Image.css';

export const RenderNSWC = ( (): JSX.Element => {
    const [sizeIndex, setSizeIndex] = useState<number>(0);
    const sizes = [{ w:"auto", h:"70px"}, {w:"50%", h:"auto"}, {w:"100%", h:"auto"}];
    return (
        <div className="Margin">
            <img className="WhiteBg Transition" src="https://www.aro.lfv.se/tor/nswc2aro.gif" height={sizes[sizeIndex].h} width={sizes[sizeIndex].w} alt="Nordic Surface Weather Chart"
            onClick={() => setSizeIndex((sizeIndex+1)%sizes.length)} />
        </div>
    )
});
